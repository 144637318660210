import React, { useEffect, useState, useCallback } from 'react';

import loadable from '@loadable/component';

import { IsGestor } from '@components/auth';
import { Layout } from '@components/layout';
import { EscalaHeader } from '@screens/escala/components/EscalaHeader';

const Escala = loadable(() => import('@screens/escala/Escala'));
const Calendario = loadable(
  () => import('@screens/escala/calendario/Calendario')
);
const Relatorio = loadable(
  () => import('@screens/escala/relatorios/Relatorio')
);
const Equipe = loadable(() => import('@screens/escala/equipe/Equipe'));

const Index = (props) => {
  return (
    <IsGestor>
      <Layout {...props}>
        <Page />
      </Layout>
    </IsGestor>
  );
};

const Page = (props) => {
  const { pageId } = props.params;

  const getComponent = useCallback(() => {
    if (!pageId || pageId === 'gestor') return <Escala {...props} />;
    if (pageId === 'calendario') return <Calendario {...props} />;
    if (pageId === 'relatorios') return <Relatorio {...props} />;
    if (pageId === 'equipe') return <Equipe {...props} />;
  }, [pageId]);

  if (!pageId || pageId === 'gestor') return getComponent();

  return (
    <div className="w-full h-full flex flex-col">
      <EscalaHeader />
      {getComponent()}
    </div>
  );
};

export default Index;
